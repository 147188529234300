import { createI18n } from "vue-i18n";

// import all the json files

import messages from "@intlify/unplugin-vue-i18n/messages";

export default createI18n({
  legacy: false,
  locale: import.meta.env.VUE_APP_I18N_LOCALE || "sv",
  fallbackLocale: import.meta.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: messages,
});